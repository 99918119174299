import { ActionMenuItemStyle, NavBtnStyle, RobinBtnStyle } from './enums';

export const RobinBtnStyles = {
  [RobinBtnStyle.Primary]: 'bg-rf-primary text-black border-black',
  [RobinBtnStyle.Outline]: 'bg-rf-gray-f9 text-black border-black',
  [RobinBtnStyle.Destructive]: 'bg-rf-red text-white border-black',
  [RobinBtnStyle.Disabled]: 'disabled:cursor-not-allowed disabled:bg-rf-gray-e7 disabled:text-rf-gray-75 disabled:border-rf-gray-75',
};

export const NavBtnStyles = {
  [NavBtnStyle.Transparent]: `text-black
  bg-white
  mobile:hover:bg-white
  transition
  hover:bg-rf-gray-f9
  active:bg-rf-gray-f9
  focus-visible:bg-rf-gray-f9`,

  [NavBtnStyle.Default]: `text-rf-gray-75
  bg-rf-gray-f9
  mobile:hover:bg-rf-gray-f9
  transition
  hover:bg-rf-gray-e7
  active:bg-rf-gray-e7
  focus-visible:bg-rf-gray-e7`,

  [NavBtnStyle.Active]: `text-rf-secondary
  bg-rf-secondary/5
  mobile:hover:bg-rf-secondary/5
  transition
  hover:bg-rf-secondary/10
  active:bg-rf-secondary/10
  focus-visible:bg-rf-secondary/10`,

  [NavBtnStyle.Danger]: `text-rf-red
  bg-rf-red/5
  mobile:hover:bg-rf-red/5
  transition
  hover:bg-rf-red/10
  active:bg-rf-red/10
  focus-visible:bg-rf-red/10`,
};

export const ActionMenuItemStyles = {
  [ActionMenuItemStyle.Default]: `text-black
  bg-white
  mobile:hover:bg-white
  transition
  hover:bg-rf-gray-e7
  active:bg-rf-gray-e7
  focus-visible:bg-rf-gray-e7`,

  [ActionMenuItemStyle.Active]: `text-rf-secondary
  bg-white
  mobile:hover:bg-white
  transition
  hover:bg-rf-secondary/10
  active:bg-rf-secondary/10
  focus-visible:bg-rf-secondary/10`,

  [ActionMenuItemStyle.Danger]: `text-rf-red
  bg-white
  mobile:hover:bg-white
  transition
  hover:bg-rf-red/10
  active:bg-rf-red/10
  focus-visible:bg-rf-red/10`,
};

export const PreviewSheetClass = 'preview-sheet';
