import { useNavigate } from '@remix-run/react';
import { Route } from '~/config/enums';
import { renderIcon } from './RobinIcons';

interface RobinLogoProps {
  clickable?: boolean;
}

export default function RobinLogo({ clickable = true }: RobinLogoProps) {
  const navigate = useNavigate();

  const habdleClick = () => {
    if (clickable) {
      navigate(Route.Home);
    }
  };

  return (
    <button className="flex justify-center items-center gap-1 px-2" onClick={habdleClick}>
      {renderIcon('logo', 10)}

      <h1 className="flex robin-regular-24">RobinFeed</h1>
    </button>
  );
}
