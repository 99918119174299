import { ReactNode } from 'react';
import { cn } from '~/lib/utils';

interface MainProps {
  children: ReactNode;
  classNames?: string;
}

export default function Main({ children, classNames }: MainProps) {
  return <main className={cn('flex flex-col flex-grow w-full mt-14 justify-center items-center sm:max-w-lg xl:max-w-xl mx-auto', classNames)}>{children}</main>;
}
