import { ReactNode } from 'react';

interface HeaderProps {
  children: ReactNode;
}

export default function Header({ children }: HeaderProps) {
  return (
    <header
      className="flex h-14 px-2 shadow-md bg-white items-center justify-between
    fixed top-0 left-0 right-0 z-40"
    >
      {children}
    </header>
  );
}
